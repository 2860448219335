body {
  // The background-color attribute will be overwritten by JavaScript initialization:
  background-color: lightskyblue;
  font-size: 18px;

  color: darkblue;
  margin: 16px;
  font-family: 'Play', sans-serif;
}

.title {
  text-align: center;
}

.tagline {
  font-style: italic;
  text-align: center;
}

.contact-us {
  text-align: center;
  font-size: 24px;
  padding-top: 25px;
}

.footer {
  padding-top: 40px;
  text-align: center;
  font-size: 14px
}
